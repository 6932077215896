import { LanguageType } from 'types/languages';
import LearningMaterialsPicture from './LearningMaterialsPicture/LearningMaterialsPicture';
import Paragraph from 'components/TextComps/Paragraph';
import { ReactComponent as WarningIcon } from '../../../../images/icons/Warning.svg';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStores } from 'index';

type Props = {
  mediaError: boolean;
  setSaveButtonDisabled: (value: boolean) => void;
};

export const LearningMaterialPictures = observer(({ mediaError, setSaveButtonDisabled }: Props) => {
  const lms = useStores().learningMaterialStore;

  useEffect(() => {
    lms.learningMaterial.medias?.length && setSaveButtonDisabled(false);
  }, [lms.learningMaterial.medias, lms.learningMaterial.medias?.length]);

  return (
    <div className='media-container'>
      <div className='warning'>
        <WarningIcon />
        <Paragraph>
          <>Please note: For unfilled fields next to individual languages, the image for English will be used as the default.</>
        </Paragraph>
      </div>
      {mediaError && (
        <Paragraph>
          <span className='error'>Please select an image for selected language(s)</span>
        </Paragraph>
      )}
      {lms.learningMaterial.languages.map((language: LanguageType) => {
        return (
          <LearningMaterialsPicture
            key={language}
            pictureId={lms.learningMaterial.medias.find((media) => media.countryCode === language)?.mediaId ?? ''}
            countryCode={language}
          />
        );
      })}
    </div>
  );
});

export default LearningMaterialPictures;
