import aboutCertificationDe from './de/AboutCertification.de.json';
import aboutCertificationEn from './en/AboutCertification.en.json';
import aboutCertificationEs from './es/AboutCertification.es.json';
import aboutCertificationJa from './ja/AboutCertification.ja.json';
import aboutCertificationKo from './ko/AboutCertification.ko.json';
import aboutCertificationZh from './zh/AboutCertification.zh.json';
import applyForMasterDe from './de/ApplyForMaster.de.json';
import applyForMasterEn from './en/ApplyForMaster.en.json';
import applyForMasterEs from './es/ApplyForMaster.es.json';
import applyForMasterJa from './ja/ApplyForMaster.ja.json';
import applyForMasterKo from './ko/ApplyForMaster.ko.json';
import applyForMasterZh from './zh/ApplyForMaster.zh.json';
import billingInformationDe from './de/BillingInformation.de.json';
import billingInformationEn from './en/BillingInformation.en.json';
import billingInformationEs from './es/BillingInformation.es.json';
import billingInformationJa from './ja/BillingInformation.ja.json';
import billingInformationKo from './ko/BillingInformation.ko.json';
import billingInformationZh from './zh/BillingInformation.zh.json';
import calculatorsDe from './de/Calculators.de.json';
import calculatorsEn from './en/Calculators.en.json';
import calculatorsEs from './es/Calculators.es.json';
import calculatorsJa from './ja/Calculators.ja.json';
import calculatorsKo from './ko/Calculators.ko.json';
import calculatorsZh from './zh/Calculators.zh.json';
import certificateDe from './de/Certificate.de.json';
import certificateEn from './en/Certificate.en.json';
import certificateEs from './es/Certificate.es.json';
import certificateJa from './ja/Certificate.ja.json';
import certificateKo from './ko/Certificate.ko.json';
import certificateZh from './zh/Certificate.zh.json';
import classPageDe from './de/ClassPage.de.json';
import classPageEn from './en/ClassPage.en.json';
import classPageEs from './es/ClassPage.es.json';
import classPageJa from './ja/ClassPage.ja.json';
import classPageKo from './ko/ClassPage.ko.json';
import classPageZh from './zh/ClassPage.zh.json';
import cookiePolicyDe from './de/CookiePolicy.de.json';
import cookiePolicyEn from './en/CookiePolicy.en.json';
import cookiePolicyEs from './es/CookiePolicy.es.json';
import cookiePolicyJa from './ja/CookiePolicy.ja.json';
import cookiePolicyKo from './ko/CookiePolicy.ko.json';
import cookiePolicyZh from './zh/CookiePolicy.zh.json';
import editProfileDe from './de/EditProfile.de.json';
import editProfileEn from './en/EditProfile.en.json';
import editProfileEs from './es/EditProfile.es.json';
import editProfileJa from './ja/EditProfile.ja.json';
import editProfileKo from './ko/EditProfile.ko.json';
import editProfileZh from './zh/EditProfile.zh.json';
import generalDe from './de/General.de.json';
import generalEn from './en/General.en.json';
import generalEs from './es/General.es.json';
import generalJa from './ja/General.ja.json';
import generalKo from './ko/General.ko.json';
import generalZh from './zh/General.zh.json';
import homePageEn from './en/HomePage.en.json';
import landingPageDe from './de/LandingPage.de.json';
import landingPageEn from './en/LandingPage.en.json';
import landingPageEs from './es/LandingPage.es.json';
import landingPageJa from './ja/LandingPage.ja.json';
import landingPageKo from './ko/LandingPage.ko.json';
import landingPageZh from './zh/LandingPage.zh.json';
import languagesDe from './de/Languages.de.json';
import languagesEn from './en/Languages.en.json';
import languagesEs from './es/Languages.es.json';
import languagesJa from './ja/Languages.ja.json';
import languagesKo from './ko/Languages.ko.json';
import languagesZh from './zh/Languages.zh.json';
import learningMaterialsDe from './de/LearningMaterials.de.json';
import learningMaterialsEn from './en/LearningMaterials.en.json';
import learningMaterialsEs from './es/LearningMaterials.es.json';
import learningMaterialsJa from './ja/LearningMaterials.ja.json';
import learningMaterialsKo from './ko/LearningMaterials.ko.json';
import learningMaterialsZh from './zh/LearningMaterials.zh.json';
import mastersGalleryEn from './en/MastersGallery.en.json';
import mastersGalleryEs from './es/MastersGallery.es.json';
import mastersGalleryJa from './ja/MastersGallery.ja.json';
import mastersGalleryKo from './ko/MastersGallery.ko.json';
import mastersGalleryZh from './zh/MastersGallery.zh.json';
import modulesAndTestsDe from './de/ModulesAndTests.de.json';
import modulesAndTestsEn from './en/ModulesAndTests.en.json';
import modulesAndTestsEs from './es/ModulesAndTests.es.json';
import modulesAndTestsJa from './ja/ModulesAndTests.ja.json';
import modulesAndTestsKo from './ko/ModulesAndTests.ko.json';
import modulesAndTestsZh from './zh/ModulesAndTests.zh.json';
import organizationPageDe from './de/OrganizationPage.de.json';
import organizationPageEn from './en/OrganizationPage.en.json';
import organizationPageEs from './es/OrganizationPage.es.json';
import organizationPageJa from './ja/OrganizationPage.ja.json';
import organizationPageKo from './ko/OrganizationPage.ko.json';
import organizationPageZh from './zh/OrganizationPage.zh.json';
import partnersGalleryEn from './en/PartnersGallery.en.json';
import partnersGalleryJa from './ja/PartnersGallery.ja.json';
import partnersGalleryKo from './ko/PartnersGallery.ko.json';
import partnersGalleryZh from './zh/PartnersGallery.zh.json';
import privacyPolicyDe from './de/PrivacyPolicy.de.json';
import privacyPolicyEn from './en/PrivacyPolicy.en.json';
import privacyPolicyEs from './es/PrivacyPolicy.es.json';
import privacyPolicyJa from './ja/PrivacyPolicy.ja.json';
import privacyPolicyKo from './ko/PrivacyPolicy.ko.json';
import privacyPolicyZh from './zh/PrivacyPolicy.zh.json';
import purchaseMembershipDe from './de/PurchaseMembership.de.json';
import purchaseMembershipEn from './en/PurchaseMembership.en.json';
import purchaseMembershipEs from './es/PurchaseMembership.es.json';
import purchaseMembershipJa from './ja/PurchaseMembership.ja.json';
import purchaseMembershipKo from './ko/PurchaseMembership.ko.json';
import purchaseMembershipZh from './zh/PurchaseMembership.zh.json';
import studentsPageDe from './de/StudentsPage.de.json';
import studentsPageEn from './en/StudentsPage.en.json';
import studentsPageEs from './es/StudentsPage.es.json';
import studentsPageJa from './ja/StudentsPage.ja.json';
import studentsPageKo from './ko/StudentsPage.ko.json';
import studentsPageZh from './zh/StudentsPage.zh.json';
import termsAndConditionsDe from './de/TermsAndConditionsPage.de.json';
import termsAndConditionsEn from './en/TermsAndConditionsPage.en.json';
import termsAndConditionsEs from './es/TermsAndConditionsPage.es.json';
import termsAndConditionsJa from './ja/TermsAndConditionsPage.ja.json';
import termsAndConditionsKo from './ko/TermsAndConditionsPage.ko.json';
import termsAndConditionsZh from './zh/TermsAndConditionsPage.zh.json';
import trackmanParametersDe from './de/TrackmanParameters.de.json';
import trackmanParametersEn from './en/TrackmanParameters.en.json';
import trackmanParametersEs from './es/TrackmanParameters.es.json';
import trackmanParametersJa from './ja/TrackmanParameters.ja.json';
import trackmanParametersKo from './ko/TrackmanParameters.ko.json';
import trackmanParametersZh from './zh/TrackmanParameters.zh.json';
import unitsDe from './de/Units.de.json';
import unitsEn from './en/Units.en.json';
import unitsEs from './es/Units.es.json';
import unitsJa from './ja/Units.ja.json';
import unitsKo from './ko/Units.ko.json';
import unitsZh from './zh/Units.zh.json';
import userCultureDe from './de/UserCulture.de.json';
import userCultureEn from './en/UserCulture.en.json';
import userCultureEs from './es/UserCulture.es.json';
import userCultureJa from './ja/UserCulture.ja.json';
import userCultureKo from './ko/UserCulture.ko.json';
import userCultureZh from './zh/UserCulture.zh.json';

export const langResources = {
  'en-US': {
    aboutCertification: aboutCertificationEn,
    applyForMaster: applyForMasterEn,
    billingInformation: billingInformationEn,
    calculators: calculatorsEn,
    certificate: certificateEn,
    classPage: classPageEn,
    cookiePolicy: cookiePolicyEn,
    trackmanParameters: trackmanParametersEn,
    editProfile: editProfileEn,
    modulesAndTests: modulesAndTestsEn,
    general: generalEn,
    homePage: homePageEn,
    landingPage: landingPageEn,
    languages: languagesEn,
    learningMaterials: learningMaterialsEn,
    mastersGallery: mastersGalleryEn,
    organizationPage: organizationPageEn,
    partnersGallery: partnersGalleryEn,
    privacyPolicy: privacyPolicyEn,
    purchaseMembership: purchaseMembershipEn,
    studentsPage: studentsPageEn,
    termsAndConditions: termsAndConditionsEn,
    units: unitsEn,
    userCulture: userCultureEn,
  },
  'ko-KR': {
    aboutCertification: aboutCertificationKo,
    applyForMaster: applyForMasterKo,
    billingInformation: billingInformationKo,
    calculators: calculatorsKo,
    certificate: certificateKo,
    classPage: classPageKo,
    cookiePolicy: cookiePolicyKo,
    trackmanParameters: trackmanParametersKo,
    editProfile: editProfileKo,
    mastersGallery: mastersGalleryKo,
    modulesAndTests: modulesAndTestsKo,
    general: generalKo,
    landingPage: landingPageKo,
    languages: languagesKo,
    learningMaterials: learningMaterialsKo,
    organizationPage: organizationPageKo,
    partnersGallery: partnersGalleryKo,
    privacyPolicy: privacyPolicyKo,
    purchaseMembership: purchaseMembershipKo,
    studentsPage: studentsPageKo,
    termsAndConditions: termsAndConditionsKo,
    units: unitsKo,
    userCulture: userCultureKo,
  },
  'ja-JP': {
    aboutCertification: aboutCertificationJa,
    applyForMaster: applyForMasterJa,
    billingInformation: billingInformationJa,
    calculators: calculatorsJa,
    certificate: certificateJa,
    classPage: classPageJa,
    cookiePolicy: cookiePolicyJa,
    trackmanParameters: trackmanParametersJa,
    editProfile: editProfileJa,
    modulesAndTests: modulesAndTestsJa,
    general: generalJa,
    landingPage: landingPageJa,
    languages: languagesJa,
    learningMaterials: learningMaterialsJa,
    mastersGallery: mastersGalleryJa,
    organizationPage: organizationPageJa,
    partnersGallery: partnersGalleryJa,
    privacyPolicy: privacyPolicyJa,
    purchaseMembership: purchaseMembershipJa,
    studentsPage: studentsPageJa,
    termsAndConditions: termsAndConditionsJa,
    units: unitsJa,
    userCulture: userCultureJa,
  },
  'zh-CN': {
    aboutCertification: aboutCertificationZh,
    applyForMaster: applyForMasterZh,
    billingInformation: billingInformationZh,
    calculators: calculatorsZh,
    certificate: certificateZh,
    classPage: classPageZh,
    cookiePolicy: cookiePolicyZh,
    trackmanParameters: trackmanParametersZh,
    editProfile: editProfileZh,
    mastersGallery: mastersGalleryZh,
    modulesAndTests: modulesAndTestsZh,
    general: generalZh,
    landingPage: landingPageZh,
    languages: languagesZh,
    learningMaterials: learningMaterialsZh,
    organizationPage: organizationPageZh,
    partnersGallery: partnersGalleryZh,
    privacyPolicy: privacyPolicyZh,
    purchaseMembership: purchaseMembershipZh,
    studentsPage: studentsPageZh,
    termsAndConditions: termsAndConditionsZh,
    units: unitsZh,
    userCulture: userCultureZh,
  },
  'es-ES': {
    aboutCertification: aboutCertificationEs,
    applyForMaster: applyForMasterEs,
    billingInformation: billingInformationEs,
    calculators: calculatorsEs,
    certificate: certificateEs,
    classPage: classPageEs,
    cookiePolicy: cookiePolicyEs,
    trackmanParameters: trackmanParametersEs,
    editProfile: editProfileEs,
    mastersGallery: mastersGalleryEs,
    modulesAndTests: modulesAndTestsEs,
    general: generalEs,
    landingPage: landingPageEs,
    languages: languagesEs,
    learningMaterials: learningMaterialsEs,
    organizationPage: organizationPageEs,
    privacyPolicy: privacyPolicyEs,
    purchaseMembership: purchaseMembershipEs,
    studentsPage: studentsPageEs,
    termsAndConditions: termsAndConditionsEs,
    units: unitsEs,
    userCulture: userCultureEs,
  },
  'de-DE': {
    aboutCertification: aboutCertificationDe,
    applyForMaster: applyForMasterDe,
    billingInformation: billingInformationDe,
    calculators: calculatorsDe,
    certificate: certificateDe,
    classPage: classPageDe,
    cookiePolicy: cookiePolicyDe,
    trackmanParameters: trackmanParametersDe,
    editProfile: editProfileDe,
    modulesAndTests: modulesAndTestsDe,
    general: generalDe,
    landingPage: landingPageDe,
    languages: languagesDe,
    learningMaterials: learningMaterialsDe,
    organizationPage: organizationPageDe,
    privacyPolicy: privacyPolicyDe,
    purchaseMembership: purchaseMembershipDe,
    studentsPage: studentsPageDe,
    termsAndConditions: termsAndConditionsDe,
    units: unitsDe,
    userCulture: userCultureDe,
  },
};
