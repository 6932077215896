import { useCallback, useMemo } from 'react';

import { AdminCourseModel } from 'types/admin/courses';
import { AdminLessonModel } from 'types/admin/lessons';
import { AdminParameterModel } from 'types/admin/trackmanParameters';
import { Checkbox } from '@trackman/web-shared-components';
import { LanguageType } from 'types/languages';
import { LearningMaterialModel } from 'types/admin/learningMaterials';
import { StudyRoomLessonModel } from 'types/admin/studyroomLessons';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

type Props = {
  dataItem: AdminLessonModel | AdminCourseModel | StudyRoomLessonModel | AdminParameterModel | LearningMaterialModel;
};

export const LanguageCheckboxes = observer(({ dataItem }: Props) => {
  const ls = useStores().languagesStore;

  const lngCheckboxesData = useMemo(() => {
    return ls.languageInfo.map((lng) => {
      return {
        ...lng,
        checked: dataItem.languages.includes(lng.countryCode),
      };
    });
  }, [ls.languageInfo, dataItem.languages]);

  const handleCheckboxChange = useCallback(
    (countryCode: LanguageType) => {
      let index = dataItem.languages.indexOf(countryCode);

      if (index > -1) {
        dataItem.languages.splice(index, 1);
        dataItem.titles.splice(index, 1);
        if ('descriptions' in dataItem && dataItem.descriptions) {
          dataItem.descriptions.splice(index, 1);
        }
        if ('definitions' in dataItem && dataItem.definitions) {
          dataItem.definitions.splice(index, 1);
          dataItem.usageInfo.splice(index, 1);
          dataItem.articlesInfo?.splice(index, 1);
        }
        if ('medias' in dataItem && dataItem.medias) {
          dataItem.medias.splice(index, 1);
        }
      } else {
        dataItem.languages.push(countryCode);
        dataItem.titles.push({ countryCode: countryCode, text: '' });
        if ('descriptions' in dataItem && dataItem.descriptions) {
          dataItem.descriptions.push({ countryCode: countryCode, text: '' });
        }
        if ('definitions' in dataItem && dataItem.definitions) {
          dataItem.definitions.push({ countryCode: countryCode, text: '' });
          dataItem.usageInfo.push({ countryCode: countryCode, text: '' });
          dataItem.articlesInfo?.push({ countryCode: countryCode, text: '' });
        }
      }
    },
    [
      'descriptions' in dataItem && dataItem.descriptions,
      'definitions' in dataItem && dataItem.definitions,
      dataItem.languages,
      dataItem.titles,
    ]
  );

  return (
    <div className='admin-edit-section'>
      <h2>Language</h2>
      <div className='checkboxes-container'>
        {lngCheckboxesData.map((lng) => {
          return (
            <Checkbox
              key={lng.countryCode}
              label={lng.displayName}
              placeholder=''
              withoutBorder
              reversed
              disabled={lng.countryCode === 'en-US'}
              onChange={() => handleCheckboxChange(lng.countryCode)}
              checked={dataItem.languages.includes(lng.countryCode)}
            />
          );
        })}
      </div>
    </div>
  );
});

export default LanguageCheckboxes;
